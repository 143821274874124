<template>
    <div>
        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="姓名" size="small" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="联系电话" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="供职状态" prop="sState">
                <el-select v-model="form.sState">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="离职" value="0"></el-option>
                    <el-option label="在职" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="性别" prop="gender">
                <el-select v-model="form.gender">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="女" value="0"></el-option>
                    <el-option label="男" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="绑定微信" prop="bind">
                <el-select v-model="form.hasOpenId">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="绑定" value="1"></el-option>
                    <el-option label="未绑定" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="身份证号" prop="cardId">
                <el-input v-model="form.cardId"></el-input>
            </jy-query-item>
            <jy-query-item label="驾驶证号" prop="dlNu">
                <el-input v-model="form.dlNu"></el-input>
            </jy-query-item>
            <jy-query-item label="准驾车型" prop="dlType">
                <el-select v-model="form.dlType">
                    <el-option v-for="t in driveTypes" :key="t.rValue" :label="t.iValue" :value="t.rValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="从业资格证号" prop="qualCert">
                <el-input v-model="form.qualCert"></el-input>
            </jy-query-item>
            <jy-query-item label="资格证编码" prop="qCertNo">
                <el-input v-model="form.qCertNo"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoDriverList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoDriverReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoDriverInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesBasicInfoDriverExport') && false"
                        url="/basic/driver/export"
                        fileName="司机"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                    <el-button v-if="btnexist('wastesBasicInfoDriverImport') && false" size="small" type="primary">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="姓名" prop="cName" min-width="100"></jy-table-column>
                <jy-table-column label="联系电话" prop="phone" min-width="130"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa" min-width="150"></jy-table-column>
                <jy-table-column label="供职状态" prop="sState" min-width="80">
                    <template v-slot="scope">{{ scope.row.sState === "1" ? "在职" : "离职" }}</template>
                </jy-table-column>
                <jy-table-column label="性别" prop="gender" min-width="80">
                    <template v-slot="scope">{{ scope.row.gender === "0" ? "女" : "男" }}</template>
                </jy-table-column>
                <jy-table-column label="绑定微信">
                    <template v-slot="scope">{{ scope.row.openId ? "是" : "否" }}</template>
                </jy-table-column>
                <jy-table-column label="身份证号" prop="cardId" min-width="180"></jy-table-column>
                <jy-table-column label="驾驶证号" prop="dlNu" min-width="180"></jy-table-column>
                <jy-table-column label="准驾车型" prop="dlType" min-width="80">
                    <template v-slot="scope">{{ formatDlType(scope.row.dlType) }}</template>
                </jy-table-column>
                <jy-table-column label="从业资格证号"  min-width="180" prop="qualCert"></jy-table-column>
                <jy-table-column label="资格证编码"  min-width="180" prop="qCertNo"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa"  min-width="120"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addUpdate" @update="getList"></add-update>
        <institutions-tree :showType="[1]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import AddUpdate from "@/components/pages/admin/basic/driver/AddUpdate.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getList();
            this.setOperateList();

            this.initDicts();
        },
        mixins: [btnMixins],
        methods: {
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictdrivingmodel").then(res => {
                    this.driveTypes = res.detail;
                });
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAddUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoDriverUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoDriverDelete");
                        }
                    }
                ];
                this.operateList = l;
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                let url = "/base/driver/queryPage";
                this.$http
                    .post(
                        url,
                        {
                            driverInfo: this.form,
                            hasOpenId: this.form.hasOpenId,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.sysOrg,
                                ...v.driverAttach,
                                ...v.driverInfo,
                                ...v.driverEx
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            add() {
                this.handleAddUpdate();
            },
            handleDelete(row) {
                this.$confirm("是否删除该司机?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/base/driver/delete";
                        this.$http.post(url, row.driverId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            handleAddUpdate(options) {
                this.$refs.addUpdate.init(options);
            },
            formatTime(val) {
                return this.$util.formatMacTime(val);
            },
            formatDlType(val) {
                const one = this.driveTypes.find(v => v.rValue == val);
                return one ? one.iValue : "-";
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                form: {
                    cName: "",
                    phone: "",
                    orgId: "",
                    orgNa: "",
                    sState: "",
                    gender: "",
                    hasOpenId: "",
                    cardId: "",
                    dlNu: "",
                    dlType: "",
                    qualCert: "",
                    qCertNo: ""
                },

                pageIndex: 1,
                pageSize: 10,
                operateList: [],
                driveTypes: [],

                btnMenuId: "wastesBasicInfoDriver"
            };
        },
        components: {
            ExportExcel,
            AddUpdate,
            institutionsTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
