<template>
    <el-dialog :visible.sync="visible" :title="title" @close="close">
        <el-form ref="driverForm" :model="model" label-width="120px" size="small" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="姓名" prop="cName">
                        <el-input v-model="model.cName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="model.phone"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="所属机构" prop="orgId">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="供职状态" prop="sState">
                        <el-select v-model="model.sState">
                            <el-option label="离职" value="0"></el-option>
                            <el-option label="在职" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="性别" prop="gender">
                        <el-select v-model="model.gender">
                            <el-option label="女" value="0"></el-option>
                            <el-option label="男" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证号" prop="cardId">
                        <el-input v-model="model.cardId"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="紧急联系人" prop="contact">
                        <el-input v-model="model.contact"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="紧急联系人电话" prop="cPhone">
                        <el-input v-model="model.cPhone"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="驾驶证号" prop="dlNu">
                        <el-input v-model="model.dlNu"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="准驾车型" prop="dlType">
                        <el-select v-model="model.dlType">
                            <el-option v-for="t in driveTypes" :key="t.rValue" :label="t.iValue" :value="t.rValue"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="从业资格证号" prop="qualCert">
                        <el-input v-model="model.qualCert"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="资格证编码" prop="qCertNo">
                        <el-input v-model="model.qCertNo"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="身份证正面" prop="IDFUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleIDFUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.IDFUrl" :src="model.IDFUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="身份证反面" prop="IDBUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleIDBUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.IDBUrl" :src="model.IDBUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="驾驶证主页" prop="dlFUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleDlFUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.dlFUrl" :src="model.dlFUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="驾驶证副页" prop="dlBUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleDlBUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.dlBUrl" :src="model.dlBUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="从业资格证" prop="qCertUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleQCertUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.qCertUrl" :src="model.qCertUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="其他" prop="otherUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleOtherUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.otherUrl" :src="model.otherUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="handle_buttons">
            <el-button type="primary" size="small" @click="saveM">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </el-dialog>
</template>

<script>
    import { cloneDeep } from "lodash";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        methods: {
            close() {
                this.$refs.driverForm.resetFields();
                this.model = {
                    cName: "",
                    phone: "",
                    orgId: "",
                    orgNa: "",
                    sState: "",
                    gender: "1",
                    cardId: "",
                    contact: "",
                    cPhone: "",
                    dlNu: "",
                    dlType: "",
                    qualCert: "",
                    qCertNo: "",
                    IDFUrl: "",
                    IDBUrl: "",
                    dlFUrl: "",
                    dlBUrl: "",
                    qCertUrl: "",
                    otherUrl: ""
                };
            },
            saveM() {
                this.$refs.driverForm.validate(valide => {
                    if (valide) {
                        let url = "/base/driver/add";
                        if (this.isEdit) {
                            url = "/base/driver/update";
                        }
                        this.$http
                            .post(url, {
                                driverInfo: this.model,
                                driverAttach: {
                                    driverId: this.model.driverId,
                                    qCertNo: this.model.qCertNo,
                                    qCertUrl: this.model.qCertUrl,
                                    IDFUrl: this.model.IDFUrl,
                                    IDBUrl: this.model.IDBUrl,
                                    dlFUrl: this.model.dlFUrl,
                                    dlBUrl: this.model.dlBUrl,
                                    otherUrl: this.model.otherUrl
                                }
                            })
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.$emit("update");
                                this.visible = false;
                            });
                    }
                });
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            init(options) {
                if (options) {
                    this.isEdit = true;
                    this.$nextTick(() => {
                        this.model = cloneDeep({
                            ...options,
                            qCertNo: options.qCertNo,
                            qCertUrl: options.qCertUrl,
                            IDFUrl: options.IDFUrl,
                            IDBUrl: options.IDBUrl,
                            dlFUrl: options.dlFUrl,
                            dlBUrl: options.dlBUrl,
                            otherUrl: options.otherUrl
                        });
                    });
                    this.title = "编辑";
                } else {
                    this.isEdit = false;
                    this.title = "新增";
                }

                this.visible = true;
            },
            addInstitutions(data) {
                this.model.orgId = data.orgId;
                this.model.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleOtherAvatarSuccess({ detail }) {
                this.model.otherUrl = detail;
            },
            handleIDFUrlAvatarSuccess({ detail }) {
                this.model.IDFUrl = detail;
            },
            handleIDBUrlAvatarSuccess({ detail }) {
                this.model.IDBUrl = detail;
            },
            handleDlFUrlAvatarSuccess({ detail }) {
                this.model.dlFUrl = detail;
            },
            handleDlBUrlAvatarSuccess({ detail }) {
                this.model.dlBUrl = detail;
            },
            handleQCertUrlAvatarSuccess({ detail }) {
                this.model.qCertUrl = detail;
            },
            handleOtherUrlAvatarSuccess({ detail }) {
                this.model.otherUrl = detail;
            }
        },
        mounted() {
            this.setUploadHeader();
        },
        data() {
            return {
                visible: false,

                title: "新增",
                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},
                model: {
                    cName: "",
                    phone: "",
                    orgId: "",
                    orgNa: "",
                    sState: "",
                    gender: "1",
                    cardId: "",
                    contact: "",
                    cPhone: "",
                    dlNu: "",
                    dlType: "",
                    qualCert: "",
                    qCertNo: "",
                    IDFUrl: "",
                    IDBUrl: "",
                    dlFUrl: "",
                    dlBUrl: "",
                    qCertUrl: "",
                    otherUrl: ""
                },
                rules: {
                    cName: [{ required: true, message: "姓名不为空", trigger: "blur" }],
                    phone: [{ required: true, message: "联系电话不为空", trigger: "blur" }],
                    orgId: [{ required: true, message: "所属机构不为空", trigger: "change" }],
                    sState: [{ required: true, message: "供职状态不为空", trigger: "change" }]
                }
            };
        },
        computed: {
            driveTypes() {
                return this.$parent.driveTypes;
            }
        },
        components: {
            institutionsTree
        }
    };
</script>
<style scoped>
    .handle_buttons {
        text-align: center;
    }
</style>
